.containerStyle {
  display: flex;
}

.linkStyle {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  cursor: pointer;
  color: #FFF;
  font-size: 24px;
  background-color: #FF902F;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconStyle {
  color: white;
  font-size: 24px;
}
