#emp_attachments {
    background-color: #ffffff;
    border-radius: 5px;
  
    .attachments-header {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px;
  
      @media (min-width: 768px) {
        flex-direction: row;
      }
  
      .attachments-title {
        margin: 0;
        text-align: left;
        flex-grow: 1;
        margin-bottom: 15px;
  
        @media (min-width: 768px) {
          margin-bottom: 0;
        }
      }
  
      .attachments-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        text-align: center;
  
        @media (min-width: 576px) {
          flex-direction: row;
          gap: 15px;
        }
  
        .upload-button,
        .download-all-button {
          cursor: pointer;
          background-color: #ff902f;
          color: white;
          padding: 10px 15px;
          border-radius: 5px;
          white-space: nowrap;
          border: none;
        }
      }
    }
  
    .attachments-divider {
      border-bottom: 1.4px solid grey;
      width: 100%;
      opacity: 0.25;
      color: inherit;
      margin-top: 3px;
    }
  
    .attachments-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      @media (max-width: 768px) {
        justify-content: start;
      }
      @media (max-width: 500px) {
        justify-content: center;
      }
    }
  }
  
  .attachment-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 25px;
    position: relative;
  
    .dropdown {
      position: absolute;
      top: 2px;
      right: -4px;
  
      .btn {
        padding: 5px 10px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  
    .attachment-preview {
      height: 180px;
      width: 180px;
      background: #f8f9fa;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;
  
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  
    .attachment-name {
      margin-top: 8px;
      text-align: center;
      overflow: hidden;
      width: 100px;
      text-overflow: ellipsis;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
    margin-top: 10px;
    margin-right: 30px;
  }
  
  .pagination .btn {
    padding: 8px 12px;
    background-color: orange;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .pagination .btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination .btn.active {
    background-color: #cfcfce;
    color: black;
  }
  .no-attachments-message {
    font-size: 16px;
    color: #888;
    text-align: center;
    margin-top: 20px;
    padding: 60px;
  }
  