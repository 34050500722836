.position-relative {
    position: relative;
  }
  
  .dropdown-wrapper select {
    cursor: pointer;
    width: 100%;
    padding-right: 30px; /* Space for the icon */
  }
  
  .dropdown-wrapper .dropdown-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* To make the icon non-interactive */
    color: #aaa; /* Adjust color as needed */
  }
  